.body-m {
    /* Body M */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.light-body-m {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.body-l {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.light-body-l {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.body-xl {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'case' on, 'ss01' on, 'ss03' on,
        'cv08' on, 'cv05' on;
}

.h1-strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.h2-strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.h3-strong {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.h4-strong {
    /* H4 Strong */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    /* identical to box height, or 165% */

    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.h5-strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.small {
    /* Small */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */

    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
}

.caption-u {
    /* CAPTION_U */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    /* or 154% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv08' on;
}

.h5-strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    /* or 160% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv08' on, 'cv05' on;
}
