.dropdown-wrapper {
    position: relative;
}

.data-title {
    position: relative;
    height: 36px;
    background: #f7f9fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.data-title span {
    padding-left: 16px;
}

.chevron-down {
    position: absolute;
    right: 14px;
    cursor: pointer;
}

.data-list-wrapper {
    position: absolute;
    max-height: 200px;
    height: 200px;
    background: #f7f9fa;
    margin-top: 2px;
    border-radius: 4px;
    padding: 10px;
    z-index: 9999;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgb(0 0 0 / 8%);
}

.data-list::-webkit-scrollbar {
    display: none;
}

.data-list-inner-wrapper {
    position: relative;
    width: 100%;
}

.data-list-search {
    position: absolute;
    outline: none;
    border: 1px solid #000000;
    box-sizing: border-box;
    background-color: transparent;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.data-list-search:focus {
    border: 1px solid blue;
}

.data-list {
    position: absolute;
    margin-top: 32px;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: overlay;
    height: 150px;
    width: 100%;
}

.data-list-item {
    height: 24px;
    width: 100%;
    cursor: pointer;
}

.data-list-item:hover {
    background-color: gray;
}
