@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,400;1,300;1,400&display=swap');
@import 'color.css';
@import 'typo.css';

@font-face {
    font-family: 'Chalkboard SE';
    src: url('../font/ChalkboardSE.otf') format('opentype');
}
