@import './assets/css/color.css';
@import './assets/css/typo.css';
@import './assets/css/main.css';

.list-wrapper {
    max-width: 1300px;
    margin: 0 auto;
    padding: 23px 0px;
}

.cursor {
    cursor: pointer;
}

.d-flex {
    display: flex;
}
.justify-right {
    justify-content: right;
}

.custom-modal-footer {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 1366px) {
    .list-wrapper {
        padding: 23px 33px;
    }
}

.loadingImage {
    display: block;
    margin: auto;
    /* width: 40px; */
}
