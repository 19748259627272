.modal-overlay-with-footer {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: overlay;
}

.modal-box-with-footer {
    display: block;
    background: white;
    padding: 1rem;
    border-radius: 1rem 1rem 0rem 0rem;
    box-sizing: border-box;
}

.modal-header-with-footer {
    display: flex;
    justify-content: space-between;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #ffffff;
    /* iOS-Web / -Y Divider */
    box-shadow: 0px -1px 0px #e3e4e5;
    border-radius: 0px 0px 16px 16px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
