.checklist-wrapper {
    display: flex;
    flex-direction: column;
}

.select-all-item {
    margin-left: 32px;
    height: 28px;
    display: flex;
    align-items: center;
}

.student-checkbox-item {
    margin-left: 40px;
    height: 40px;
    display: flex;
    align-items: center;
}

.student-profile {
    width: 40px;
    height: 40px;
    background-color: antiquewhite;
    border-radius: 50%;
    margin-right: 20px;
}
