.mt-16 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-32 {
    margin-top: 32px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-16 {
    margin-left: 16px;
    word-break: break-all;
}

.ml-60 {
    margin-left: 0px;
}
/* 
.ml-60:not(:first-child) {
    margin-left: 60px;
} */

.container {
    /* padding: 0px 32px 0px 32px; */
}

.name-input {
    width: 468px;
    height: 48px;
    padding: 0px 16px;
    border: 2px solid #e8e9eb;
    border-radius: 12px;
}

.name-input::-webkit-input-placeholder {
    color: #b1b1b2;
}

.checkables-header {
    display: flex;
    align-items: center;
}

.groups-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 2px solid #e8e9eb;
    border-radius: 16px;
    padding: 32px 20px;
    justify-content: space-between;
}

.group {
    background: #e8e9eb;
    border-radius: 20px 3px 3px 20px;
    display: flex;
    padding: 0px 10px;
}

.students-container {
    border: 2px solid #e8e9eb;
    border-radius: 16px;
    margin-bottom: 32px;
    /* border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #E8E9EB;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.08));
    border-radius: 16px 16px 0px 0px; */
}

.students-wrapper {
    display: grid;
    column-gap: 32px;
    row-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    padding: 32px 40px 16px 40px;
}

.search-wrapper {
    padding: 32px 0px 0px 40px;
}

.student {
    display: flex;
    align-items: center;
}

.student-profile {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background: #a3b8cc;
    border-radius: 100px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.student-profile-decor {
    position: absolute;
}

.students-footer {
    /* border: 2px solid #E8E9EB;
    border-radius: 0px 0px 16px 16px; */
    border-top: 2px solid #e8e9eb;
    padding: 16px 40px;
}

.mb-48 {
    margin-bottom: 48px;
}
