.grading-btn-group {
    margin-top: 49px;
    display: flex;
    justify-content: space-between;
}

.grading {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 8px 0px;
    position: relative;
}

.point-wrapper {
    width: 312px;
    height: 48px;
}

.arrow-group {
    position: absolute;
    margin-left: 312px;
    height: 48px;
}

.up-arrow {
    position: absolute;
    right: 10px;
    top: 3px;
    cursor: pointer;
}

.down-arrow {
    position: absolute;
    right: 10px;
    bottom: 3px;
    cursor: pointer;
}

.base-input {
    width: 100%;
    height: 100%;
    /* Silver / S0 - White */
    background: #ffffff;
    /* Silver / S40 */
    border: 2px solid #e8e9eb;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0px 12px;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
    /* Carbon / C300 */
    color: #363f4d;
}
