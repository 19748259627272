.styling-tool-bar {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
}

.line-for-popup {
    height: 36px;
    width: 2px;
    background: #d9ecff;
    margin: 0px 16px;
}

.flex-align-center {
    display: flex;
    align-items: center;
}
