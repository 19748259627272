.mr-16 {
    margin-right: 16px;
}

.mt-33 {
    margin-top: 33px;
}

.primary-m {
    background: #0065f2;
    border-radius: 8px;
    padding: 0px 16px;
    height: 40px;
    cursor: pointer;
}

.primary-m:disabled {
    opacity: 0.4;
    cursor: no-drop;
}
