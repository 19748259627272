.list-item {
    width: 196px;
    padding: 0 12px;
    position: relative;
}

.cover-wrapper {
    height: 266px;
    display: flex;
    align-items: center;
    border: 1px solid #e3e4e6;
    position: relative;
}

.cover-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mt-16 {
    margin-top: 16px;
}

.item-text {
    text-align: left;
}

.pencil_icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 24px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid #e8e9eb;
    transition: 0.5s ease;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.75);
}

.cover-wrapper:hover .overlay {
    opacity: 1;
}

.cover-wrapper:hover .pencil_icon {
    opacity: 0;
}

.worksheet-tag {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 8px;
}

.locked_icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
}
