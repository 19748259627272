.activity-navbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    z-index: 100;
}

.navbar-left {
    width: 30%;
    display: flex;
    align-items: center;
    padding-left: 32px;
}

.navbar-middle {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-right {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;
}

@media (min-width: 770px) {
    .navbar-left {
        width: 20%;
    }
    .navbar-middle {
        width: 60%;
    }
    .navbar-right {
        width: 20%;
    }
}

@media (max-width: 768px) {
    .navbar-left {
        width: 25%;
    }
    .navbar-middle {
        width: 50%;
    }
    .navbar-right {
        width: 25%;
    }
}
