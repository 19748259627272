.chat-pdf {
    background: #f7f9fa;
    /* Silver / S50 - Iron I */

    border: 1px solid #e3e4e6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    max-width: 272px;
    width: 272px;
    max-height: 66px;
}

.pdf-properties {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 5px;
}

.pdf-name {
    font-family: 'Chalkboard';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 28px;
    /* identical to box height, or 215% */

    font-feature-settings: 'tnum' on, 'lnum' on;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 90px);
    /* display: inline-block; */
    padding-right: 5px;
}
