.card-wrap {
    box-shadow: 0px -1px 0px #e3e4e5;
    display: flex;
    align-items: center;
    padding: 13px 24px;
}
.profile {
    padding-top: 3px;
}
.list-info {
    margin-left: 16px;
    overflow-wrap: break-word;
}
.initials {
    position: absolute;
    padding-top: 10px;
    width: 40px;
    text-align: center;
}
