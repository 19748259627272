input[type='file'] {
    display: none;
}

.thumbnail-img-upload {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 16px;
}

.thumbnail-img-upload:hover {
    background: #0043a1;
}
.thumbnail-img-upload:focus,
.thumbnail-img-upload:active {
    border: 2px solid #0065f2;
    box-sizing: border-box;
}

.upload-thumbnail {
    width: 190px;
    height: 266px;
    position: relative;
}

.upload-thumbnail > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.settings_icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 24px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid #e8e9eb;
    transition: 0.5s ease;
}

.upload-thumbnail:hover .settings_icon {
    opacity: 0;
}

.thumbnail-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.75);
}

.upload-thumbnail:hover .thumbnail-overlay {
    opacity: 1;
}

.thumbnail-overlay-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.thumbnail-menu-item {
    padding: 8px 16px;
    margin: 0px 16px;
    cursor: pointer;
}

.thumbnail-edit-btn {
    cursor: pointer;
    background: #0065f2;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 16px;
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.75);
    display: flex;
    align-items: center;
}
