.profile-outer-wrapper {
    position: absolute;
    right: 0;
    margin-top: 5px;
    z-index: 99999;
}
.profile-wrapper {
    position: absolute;
    z-index: 12;
    width: 243px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    border-radius: 4px;
    right: 0;
    top: 8px;
    display: flex;
    flex-direction: column;
}

.profile-outer-wrapper::before {
    content: '';
    position: absolute;
    transform: rotate(135deg);
    background-color: #ffffff;
    padding: 8px 7.5px;
    z-index: 13;
    overflow: hidden;
    right: 12.5px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 20%);
}

.triangle-with-shadow {
    width: 14.5px;
    height: 8px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 16px 10px -17px rgba(255, 255, 255, 0.5);
    z-index: 12;
}

.profile-wrapper_top {
    padding: 20px 16px 12px 16px;
    display: flex;
    align-items: center;
}

.profile-wrapper_bottom {
    padding: 8px 16px 8px 16px;
    border-top: 1px solid #edeff0;
}

.profile-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.profile-image .student-initials {
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image img {
    /* position: absolute; */
    width: 40px;
    height: 40px;
}

.profile-wrapper_top span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 53px);
}

.profile-wrapper_bottom div {
    display: flex;
}

.profile-wrapper_bottom div span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 83px);
}

.qr-login-btn {
    padding: 7px 15px;
    background: #d9ecff;
    border: 1px solid #f7f9fa;
    border-radius: 8px;
    color: #0043a1;
    align-items: center;
    cursor: pointer;
}
.qr-icon {
    margin-right: 12px;
}
