.student-input-wrapper {
    position: relative;
    overflow-y: overlay;
    overflow-x: hidden;
    box-sizing: border-box;
}

.view-layout {
    padding: 12px 14px;
    overflow: overlay;
    box-sizing: border-box;
    overflow-x: hidden;
}

.student-input-tool-bar {
    background: #d9ecff;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: #000000;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

.student-input-tool-bar-transparent{
    background: transparent;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

.auto-resize-textbox {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    outline: none;
    /* border: 1px solid #e3e4e6; */
    border-radius: 4px;
    overflow: hidden;
    padding: 5px 28px 5px 14px;
    white-space: pre-wrap;

    font-family: 'Chalkboard';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

.owner{
    border-radius: 4px;
    width: fit-content;
    margin-top: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding-left: 14px;
    display: flex;
}

.lock-user {
    padding: 0px 9px;
    background: silver;
    width: max-content;
    float: right;
    margin-top: 9px;
    z-index: 10;
    position: relative;
}

#render-textbox {
    position: relative;
    width: 100%;
    height: max-content;
}

#render-textbox img {
    position: absolute;
    right: 6px;
    bottom: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: silver transparent;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
    width: 6px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 6px;
    border: 3px solid transparent;
}
