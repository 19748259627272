.chat-tool-wrapper {
    background: #d9ecff;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    float: right;
    margin: 8px 0px;
    margin-left: 16px;
}

.chat-element-wrapper {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
    clear: both;
    transition: all ease 0.2s;
    position: relative;
    background: #F7F9FA;
    border: 1px solid #E3E4E6;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.chat-element-wrapper > img,
.chat-element-wrapper > video,
.chat-element-wrapper-audio >audio
{
    box-sizing: border-box;
}
.chat-element-wrapper-pdf .chat-pdf{
    margin: 8px 16px 16px 16px;
    box-sizing: border-box;
}
.chat-element-wrapper-pdf,
.chat-element-wrapper-audio {
    width: fit-content;
    margin-bottom: 15px;
    border-radius: 4px;
    clear: both;
    transition: all ease 0.2s;
    position: relative;
    background: #F7F9FA;
    border: 1px solid #E3E4E6;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

#edit-textbox {
    position: relative;
    width: 100%;
    height: max-content;
}

#edit-textbox > img {
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}