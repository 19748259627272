.modal-text-input {
    width: 468px;
    height: 48px;
    padding: 0px 16px;
    border: 2px solid #e8e9eb;
    border-radius: 12px;
    box-sizing: border-box;
}

.modal-text-input::-webkit-input-placeholder {
    color: #b1b1b2;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 32px 20px;
    border: 2px solid #e8e9eb;
    border-radius: 16px;
}

.label-item {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0px 10px;
    margin-left: 10px;
    border-radius: 20px 3px 3px 20px;
}

.label-item > .icon-wrapper {
    margin-bottom: -4px;
    padding-right: 8px;
}

.radio-btn {
    display: flex;
    align-items: center;
}

.radio-btn > input {
    width: 20px;
    height: 20px;
    margin: 0px;
}

/* input[type='file'] {
    display: none;
}

.thumbnail-img-upload {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-thumbnail {
    width: 190px;
    height: 266px;
}

.upload-thumbnail > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
} */

.mt-16 {
    margin-top: 16px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mt-123 {
    margin-top: 123px;
}
