.submission-view-wrapper {
    display: flex;
    height: calc(100vh - 120px);
}

.sidebar-container {
    width: 20%;
    height: 100%;
    overflow: overlay;
}

.canvas-container {
    width: 80%;
}

.center-wrapper {
    text-align: center;
    padding: 20px;
}

.share-modal {
    margin-top: 32px;
}

.link-wrapper {
    background: #f7f9fa;
    /* Silver / S40 */

    border: 2px solid #e8e9eb;
    border-radius: 12px;
    padding: 0px 22px;
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.link {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
}
.mt-80 {
    margin-top: 80px;
}
.mt-18 {
    margin-top: 18px;
}
