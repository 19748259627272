.activity-header {
    margin: 22px 13px;
}
.activity-list {
    width: 100%;
    margin: auto;
    margin-bottom: 64px;
}
.activities {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding-top: 14px;
}
.list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
}
.students-col {
    width: 24%;
    padding-left: 24px;
}
.actions-col {
    width: 52%;
}
.date-time-col {
    width: 17%;
    padding-right: 24px;
}
.loading-wrapper {
    height: 76px;
    background: #ffffff;
    box-shadow: 0px -1px 0px #e3e4e5;
    align-items: center;
    padding: 0px 24px;
    display: flex;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.pg-loading {
    justify-content: right;
}
.pg-text {
    margin-right: 7px;
}
@media (min-width: 1072px) {
    .activity-list {
        width: 1072px;
    }
}
