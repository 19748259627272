.overlay-menu {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.menu-item {
    padding: 8px 16px;
    margin: 0px 16px;
    cursor: pointer;
}

.menu-item:last-child {
    margin-bottom: 16px;
}

.menu-item:hover {
    background: #0065f2;
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.75);
    border-radius: 8px;
}
