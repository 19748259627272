.drag-drop,
.upload-item {
    width: 100%;
    height: 352px;
    border: 1px dashed #b1b1b3;
    border-radius: 10px;
    margin-top: 29px;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
}

.placeholder {
    margin-top: 129px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.modal-btn-group {
    margin-top: 49px;
    display: flex;
    justify-content: flex-end;
}

input[type='file'] {
    display: none;
}

.file-input {
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 160px;
    height: 40px;
    z-index: 10;
}

.custom-file-upload {
    cursor: pointer;
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-item > .file-info {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
