.search-input {
    display: flex;
    align-items: center;
    width: 446px;
    padding: 12px 16px;
    border: 2px solid #e8e9eb;
    border-radius: 12px;
    box-sizing: border-box;
}

.search-input::placeholder {
    color: #5c6773;
}

.search-input input {
    padding-left: 12px;
    border: none;
    outline: none;
    width: calc(100% - 24px);
}
