.header-navbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    z-index: 1;
}

.navbar-left {
    width: 30%;
    display: flex;
    align-items: center;
    padding-left: 32px;
}

@media (min-width: 770px) {
    .navbar-left {
        width: 20%;
    }
}

@media (max-width: 768px) {
    .navbar-left {
        width: 25%;
    }
}
