.ghost-wrapper {
    position: absolute;
    width: calc(100% - 32px);
    height: 54px;
    z-index: 10;
    cursor: pointer;
}

.chat-audio {
    max-width: 300px;
    display: flex;
    height: 54px;
    margin:12px;
}
