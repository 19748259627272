.feedback-tool {
    width: fit-content;
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-end; */
}

.feedback-content {
    box-sizing: border-box;
    width: 323px;
    height: 63px;
    background: #FFFFFF;
    border: 1px solid #D4D5D6;
    border-radius: 6px;
    margin-left: 12px;
    resize: none;
    padding: 8px;
}

.feedback-body {
    width: 320px;
    height: 63px;
    border: none;
    outline: none;
    resize: none;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

textarea::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #B1B1B3;;
}

/* .feedback-body {
    margin-top: 3px;
    width: 100%;
} */
