label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
label input[type='checkbox'] {
    display: none;
}
label input[type='checkbox']:checked + .cb-wrapper .cb-icon {
    background: #0065f2;
    border-radius: 4px;
    border: none;
}
label input[type='checkbox']:checked + .cb-wrapper .cb-icon::before {
    display: block;
}
.cb-wrapper .cb-icon {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    border: 2px solid #475466;
    border-radius: 4px;
    transition: all 0.5s ease;
    box-sizing: border-box;
}
.cb-wrapper .cb-icon::before {
    content: '';
    top: 1px;
    left: 6px;
    width: 5px;
    height: 12px;
    display: none;
    position: absolute;
    transform: rotate(45deg);
    transition: all 0.5s ease;
    border-top: 0px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 0px solid transparent;
}

.student-list {
    padding: 8px 0px;
}
