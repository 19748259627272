.default {
    position: absolute;
    bottom: 31px;
    left: 21px;
    background: #ffffff;
    padding: 12px 14px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgb(0 0 0 / 8%);
    display: flex;
    align-items: center;
    z-index: 9999;
}

.top-right-alert {
    position: fixed;
    top: 60px;
    right: 0;
    background: #ffffff;
    padding: 12px 14px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgb(0 0 0 / 8%);
    display: flex;
    align-items: center;
    z-index: 9999;
}

.default > img, .top-right-alert > img {
    margin-right: 14px;
}
