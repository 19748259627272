.drag-drop,
.upload-item,
.live-item {
    width: 100%;
    height: 352px;
    border: 1px dashed #b1b1b3;
    border-radius: 10px;
    margin-top: 29px;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.live-item {
    display: flex;
    flex-direction: column;
}

.placeholder {
    margin-top: 129px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.modal-btn-group {
    margin-top: 49px;
    display: flex;
    justify-content: flex-end;
}

input[type='file'] {
    display: none;
}

.file-input {
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 160px;
    height: 40px;
    z-index: 10;
}

.btn-gp-for-modal {
    bottom: 32px;
    position: absolute;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
}

.btn-gp-for-modal label {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.btn-gp-for-modal button {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.live-video {
    object-fit: contain;
    height: 80%;
}

.record-btn,
.stop-btn {
    width: 113px;
    padding: 5px;
    background: #0065f2;
    border-radius: 8px;
    cursor: pointer;
}

.record-btn {
    margin-right: 16px;
}

.live-item-btn {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recording-text {
    text-align: center;
}

@media (max-width: 420px) {
    .btn-gp-for-modal button,
    .btn-gp-for-modal label {
        width: 114px;
    }
}
