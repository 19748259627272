.draggable {
    position: absolute;
    /* box-sizing: border-box; */
}

.tool-wrapper {
    padding: 8px 16px;
    height: 40px;
    background: #d9ecff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: -70px;
    position: absolute;
    left: 0;
    z-index: 999;
}
