.tool-bar {
    box-sizing: border-box;
    position: sticky;
    top: 60px;
    width: 100%;
    height: 60px;
    /* padding: 0px 10px 0px 32px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.line {
    width: 1px;
    height: 24px;
    background-color: #c3d4e5;
    margin: 0px 12px;
}

.icon-group {
    padding-left: 32px;
}

.btn-group {
    padding-right: 10px;
}

.icon-group,
.btn-group {
    display: flex;
    align-items: center;
}

.journal-wrapper {
    position: relative;
}

.notification {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffcfcc;
    /* Silver / S0 - White */
    border: 1px solid #ffffff;
    border-radius: 12px;
    position: absolute;
    margin-left: 22px;
    margin-top: -4px;
    z-index: 10;
}

.history {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background: #edeef0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 32px;
}

.mr-30 {
    margin-right: 30px;
}
