.popup-tool {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.popup-content {
    /* width: 323px;
    height: 112px; */
    left: 242px;
    top: 309px;
    background: #f3f3f3;
    /* Silver / S60 */
    border: 3px solid #d4d5d6;
    border-radius: 6px;
    padding: 18px;
    /* white-space: nowrap; */
    box-sizing: border-box;
}

.popup-title,
.popup-body {
    border: none;
    outline: none;
}

.popup-body {
    margin-top: 3px;
    width: 100%;
}
