.text-c75 {
    color: #7a8a99;
}
.text-c100 {
    color: #5c6773;
}

.text-c200 {
    color: #475466;
}

.text-c300 {
    color: #363f4d;
}

.text-s0 {
    color: #ffffff;
}

.text-s70 {
    color: #b1b1b3;
}

.text-b100 {
    color: #0065f2;
}

.text-r100 {
    color: #e53c39;
}

.text-r300 {
    color: #8c1e1c;
}

.text-g300 {
    color: #00593c;
}

.bg-c25 {
    background-color: #c3d4e5;
}

.bg-s10 {
    background: #f7f9fa;
}

.bg-s30 {
    background: #edeef0;
}

.bg-s40 {
    background: #e8e9eb;
}

.bg-b100 {
    background: #0065f2;
}

.bg-b50 {
    background: #8cc4ff;
}

.bg-g100 {
    background: #00a653;
}

.bg-c75 {
    background: #7a8a99;
}

.bg-s0 {
    background: #ffffff;
}

.bg-g50 {
    background: #91f2ba;
}

.bg-b25 {
    background: #d9ecff;
}

.text-c50{
    color: #A3B8CC;
}

.bg-c50{
    background-color: #A3B8CC;
}

.text-c75{
    color: #7A8A99;
}

.bg-c75{
    background-color: #7A8A99;
}
