html {
    -webkit-text-size-adjust: none;
    touch-action: pan-y; /*prevent user scaling*/
}

body {
    background-color: #f7f9fa;
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
}

button {
    border: none;
    outline: none;
}
