.locked-icon {
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    cursor: pointer;
}

.pencil-icon {
    cursor: pointer;
    margin: 0px 16px;
}

.arrow-icon {
    margin-right: 11px;
    cursor: pointer;
}

.toolbar-icon,
.student-toolbar-icon {
    /* margin-right: 26px; */
    width: 48px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.journal-icon,
.user-icon,
.history-icon {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background: #edeef0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
}

.student-user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background: #edeef0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 25px;
}

.journal-icon,
.history-icon {
    margin-right: 24px;
}

.move-icon,
.edit-icon,
.delete-icon,
.resize-icon {
    cursor: pointer;
}

.move-icon,
.edit-icon {
    margin-right: 20px;
}

.cancel-popup {
    position: absolute;
    right: 4px;
    margin-top: -12px;
    margin-right: 0px;
    cursor: pointer;
}

.popup-list-icon,
.popup-list-number {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
}

.popup-component-icon {
    width: 32px;
    height: 32px;
    background: #8cc4ff;
    border: 1px solid #d4d5d6;
    box-shadow: 2px 4px 32px rgb(0 0 0 / 8%);
    border-radius: 6px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
}

.feedback-component-icon {
    width: 40px;
    height: 40px;
    background: #ffcfcc;
    border: 1px solid #d4d5d6;
    box-shadow: 2px 4px 32px rgb(0 0 0 / 8%);
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}

.group-icon {
    width: 36px;
    height: 36px;
    /* Silver / S0 - White */
    background: #ffffff;
    /* iOS-Web / Elevation 02 (Y) */
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active-bar {
    background: #ffea80;
    border-radius: 4px 4px 0px 0px;
    width: 48px;
    height: 4px;
    position: absolute;
    bottom: 0px;
}

.preview-icon {
    background: #ffffff;
    border: 1px solid #edeef0;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    margin-right: 20px;
}

/* state */
.preview-icon:hover,
.user-icon:hover,
.journal-icon:hover,
.history-icon:hover {
    background: #e8e9eb;
    border: 1px solid #e3e4e6;
}

.preview-icon:focus,
.preview-icon:active,
.user-icon:focus,
.user-icon:active,
.journal-icon:focus,
.journal-icon:active,
.history-icon:focus,
.history-icon:active {
    background: #f7f9fa;
    border: 2px solid #ffea80;
}
