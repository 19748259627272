.home-loading-wrapper {
    align-items: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-right: 32px;
}
.loading-list {
    display: flex;
}
.cover-loading-wrapper {
    position: relative;
}
