.primary-btn,
.primary-m,
.primary-s {
    background: #0065f2;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
}

.primary-btn {
    /* padding: 10px 16px; */
    width: 232px;
    height: 48px;
}

.primary-m,
.disabled-m {
    padding: 0px 16px;
    height: 40px;
}

.disabled-l {
    width: 113px;
    height: 40px;
    cursor: not-allowed;
    border-radius: 8px;
}

.disabled-m {
    cursor: not-allowed;
}

.primary-s {
    width: 113px;
    height: 40px;
}

.secondary-btn,
.secondary-s,
.secondary-m {
    /* Silver / S0 */
    background: #ffffff;
    /* iOS-Web / Elevation 02 (Y) */
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    cursor: pointer;
}

.secondary-s {
    width: 113px;
    height: 40px;
}

.secondary-m {
    padding: 0px 16px;
    height: 40px;
}

.secondary-btn {
    /* padding: 10px 16px; */
    width: 183px;
    height: 48px;
}

.tertiary-btn,
.tertiary-s {
    background: #f2f3f5;
    border-radius: 8px;
    cursor: pointer;
}

.tertiary-btn {
    min-width: 140px;
    padding: 8px 16px;
}

.tertiary-s {
    width: 113px;
    height: 40px;
}

.disabled {
    /* Silver / S40 */
    background: #e8e9eb;
    border-radius: 8px;
}

.ml-18 {
    margin-left: 18px;
}

.mr-24 {
    margin-right: 24px;
}

.ml-24 {
    margin-left: 24px;
}

.mr-26 {
    margin-right: 26px;
}

.mr-20 {
    margin-right: 20px;
}

.gallery-upload {
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 160px;
    height: 40px;
}

.modal-btn {
    width: 113px;
    height: 40px;
    cursor: pointer;
}

.delete-btn {
    min-width: 140px;
    background: #e63c39;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.modal-btn-l {
    width: 144px;
    height: 40px;
    cursor: pointer;
}

.nav-back-btn {
    background: #f2f3f5;
    border-radius: 8px;
    cursor: pointer;
    width: 113px;
    height: 40px;
}

.save-worksheet-btn {
    background: #fff2b3;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-left: 20px;
}

.save-worksheet-btn-loading {
    background: rgba(255, 242, 179, 0.3);
    /* iOS-Web / Elevation 02 (Y) */

    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.save-worksheet-btn:hover {
    background: #ffea80;
}

.save-worksheet-btn:focus,
.save-worksheet-btn:active,
.save-worksheet-btn:visited {
    border: 2px solid #ffea80;
    border-radius: 14px;
}

.save-worksheet-btn:focus:before {
    content: ' ';
    display: block;
    width: 5px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 100%;
    transition: all 0.5s;
}

.share-btn {
    width: 74px;
    height: 40px;
    padding: 0px 14px;
    background: #91f2ba;
    box-shadow: 0px 0px 0px 1px #edeef0, 1px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
}

.copy-btn {
    width: 144px;
    height: 40px;
    background: #0065f2;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    float: right;
    margin-top: 32px;
}

.copy-btn:hover {
    background: #0043a1;
}

.copy-btn:active,
.copy-btn:focus {
    border: 2px solid #0065f2;
    padding: 6px;
}

.primary-btn:hover,
.primary-m:hover,
.primary-s:hover {
    background: #0043a1;
}

.primary-btn:active,
.primary-m:active,
.primary-s:active,
.primary-btn:focus,
.primary-m:focus,
.primary-s:focus {
    background: #0043a1;
    border: 2px solid #0065f2;
    box-sizing: border-box;
}

.secondary-btn:hover,
.secondary-s:hover,
.secondary-m:hover {
    background: #f2f3f5;
}

.secondary-btn:focus,
.secondary-s:focus,
.secondary-m:focus,
.secondary-btn:active,
.secondary-s:active,
.secondary-m:active {
    border: 2px solid #0065f2;
    box-sizing: border-box;
}

.tertiary-btn:hover,
.tertiary-s:hover {
    background: #e3e4e6;
}

.tertiary-btn:focus,
.tertiary-s:focus,
.tertiary-btn:active,
.tertiary-s:active {
    box-sizing: border-box;
    border: 2px solid #f2f3f5;
}

.share-btn:hover {
    background: #52cc85;
}

.share-btn:focus,
.share-btn:active {
    border: 2px solid #91f2ba;
}

.delete-btn:hover {
    background: #bf2926;
}

.delete-btn:active,
.delete-btn:focus {
    border: 2px solid #e63c39;
}
