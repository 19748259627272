.popup-list-wrapper {
    background: #f7f9fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 6px 16px;
}

.popup-list-wrapper > .popup-list-icon {
    margin-left: 0px;
}

.popup-list-wrapper > .popup-list-icon ~ .popup-list-icon {
    margin-left: 12px;
}

.popup-list-wrapper > .popup-list-number {
    margin-left: 16px;
}

.popup-list-wrapper > .popup-list-number ~ .popup-list-number {
    margin-left: 16px;
}
