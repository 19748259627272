.group-btn-gp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;
}

.group-wrapper {
    margin-top: 16px;
    width: 100%;
    min-height: 400px;
}

.group-list {
    margin: 24px 0px 0px 0px;
    max-height: 325px;
    overflow: overlay;
}
