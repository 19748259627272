.grading-wrapper {
    width: max-content;
    height: max-content;
}
.mark {
    border: 1px solid #d4d5d6;
    border-radius: 4px;
    height: 28px;
    padding: 8px;
    width: 35px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv08' on, 'cv05' on;
    box-sizing: border-box;
    margin-right: 4px;
}
