.student-tool-bar {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 0px 10px 0px 32px;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
}

.student-toolbar-left {
    width: 100%;
    display: flex;
    justify-content: center;
}

.right-btn-group {
    /* width: 20%; */
    display: flex;
    justify-content: flex-end;
}

.active-right-btn-group {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

.active-student-profile {
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
}

.active-student-profile-count {
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-student-profile img,
.active-student-profile span {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.profile-name-tooltip {
    position: absolute;
    margin-top: 40px;
    z-index: 999;
    background: #5c6773;
    padding: 6px 14px;
    border-radius: 8px;
}

.student-profile-outer-wrapper {
    position: absolute;
    right: 0;
    margin-top: 5px;
}
.student-profile-wrapper {
    position: absolute;
    z-index: 12;
    padding: 6px 14px;
    width: max-content;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    background: #5c6773;
    border-radius: 8px;
    right: 0;
    top: 8px;
    display: flex;
    flex-direction: column;
}

.student-profile-outer-wrapper::before {
    content: '';
    position: absolute;
    transform: rotate(135deg);
    background-color: #5c6773;
    padding: 8px 7.5px;
    z-index: 13;
    overflow: hidden;
    right: 12.5px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 20%);
}
.my-wrapper {
    position: relative;
}
