.activity-list {
    width: 100%;
    margin: auto;
    margin-bottom: 64px;
}

.activity-header {
    margin: 22px 13px;
}

.activities {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding-top: 14px;
}
.list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
}

.students-col {
    width: 24%;
    padding-left: 24px;
}
.actions-col {
    width: 52%;
}
.date-time-col {
    width: 17%;
    padding-right: 24px;
}

@media (min-width: 1072px) {
    .activity-list {
        width: 1072px;
    }
}

.pagination-wrapper {
    padding: 20px;
    box-shadow: 0px -1px 0px #e3e4e5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pagination {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0px;
    justify-content: flex-end;
    margin: 0px;
}

.page-item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #7A8A99;
    padding: 0px 20px;
    cursor: pointer;
}

.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #0065F2;
    padding: 0px 20px;
    cursor: pointer;
}

.page-change-btn {
    padding: 8px 20px;
    gap: 12px;
    background: #0065F2;
    color: white;
    border-radius: 8px;
    cursor: pointer;
}

.page-change-btn-disabled {
    padding: 8px 20px;
    background: #E8E9EB;
    border-radius: 8px;
    color: white;
}


.range {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    margin-right: 42px;
}

.range-label {

    color: #B1B1B3;

}

.range-value {
    margin-left: 8px;
    color: #5C6773;
}