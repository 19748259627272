.score-wrapper {
    display: flex;
    flex-direction: column;
}

.score-top {
    width: 95px;
    height: 34px;
    background: #0065f2;
    /* Silver / S60 */
    border: 1px solid #d4d5d6;
    border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-bottom {
    width: 95px;
    height: 34px;
    /* Silver / S0 - White */
    background: #ffffff;
    /* Silver / S60 */
    border: 1px solid #d4d5d6;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
