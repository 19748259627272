.tool-bar-user {
    margin-right: 22px;
    position: relative;
}

.student-toolbar-btn-group {
    display: flex;
    position: absolute;
    right: 0;
}

.toolbar-align {
    justify-content: center;
}

.toolbar-title {
    text-align: center;
    width: calc(100% - 376px);
}

.truncate-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 760px) {
    /* .student-toolbar-left {
        width: 70% !important;
    }

    .right-btn-group {
        width: 30% !important;
    } */
    .active-right-btn-group {
        width: 30% !important;
    }
}

@media (max-width: 600px) {
    .student-tool-bar {
        padding: 0px 10px 0px 10px !important;
    }
    .student-toolbar-left {
        justify-content: flex-start !important;
        width: calc(100% - 200px) !important;
    }

    .student-toolbar-left span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .right-btn-group {
        width: 200px !important;
    }

    .tool-bar-user {
        margin-right: 0px;
    }

    .student-user-icon {
        margin-left: 8px;
    }

    .toolbar-align {
        justify-content: left;
    }

    .toolbar-title {
        text-align: left;
        width: calc(100% - 188px);
    }
}
