.textbox-tool {
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
}

.fake-tool-bar {
    background: #d9ecff;
    border-style: solid;
    border-top: 2px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-color: #000000;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    box-sizing: border-box;
    position: absolute;
}
