/* CUSTOM SHIMMER */

.defaultLoading {
    animation: shimmer 3s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 80%);
    background-size: 1000px 100%;
    height: 25px;
    border-radius: 20px;
    width: 100%;
}

.profileLoading {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 16px;
}

.nameLoading {
    width: 130px;
    height: 18px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 67px;
}
.action1Loading {
    width: 505px;
    height: 18px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 56px;
}
.action2Loading {
    width: 190px;
    height: 18px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-top: 8px;
}
.dateLoading {
    width: 200px;
    height: 18px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
}
.pg1Loading {
    width: 80px;
    height: 10px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 40px;
}
.pg2Loading {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 20px;
}
.pg3Loading {
    width: 100px;
    height: 18px;
    border-radius: 16.25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-right: 20px;
}
.listPosterLoading {
    width: 190px;
    height: 271px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin-bottom: 16px;
}
.listNameLoading1 {
    width: 186px;
    height: 14px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 18.75px;
    margin-bottom: 8px;
}
.listNameLoading2 {
    width: 119px;
    height: 14px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 18.75px;
}
.pencilLoading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 24px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
}
.createLoading1 {
    width: 153px;
    height: 24px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 18.75px;
    margin-bottom: 20px;
}
.createLoading2 {
    width: 468px;
    height: 48px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 8px;
    margin-bottom: 20px;
}
.createLoading3 {
    width: 106px;
    height: 24px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 18.75px;
    margin-bottom: 20px;
}
.createLoading4 {
    width: 888px;
    height: 89px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 8px;
    margin-bottom: 20px;
}
.createLoading5 {
    width: 325px;
    height: 24px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 18.75px;
    margin-bottom: 20px;
}
.createLoading6 {
    width: 145px;
    height: 40px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 8px;
    margin-bottom: 80px;
}
.createLoading7 {
    width: 145px;
    height: 40px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 8px;
    margin-right: 21px;
}
.listTileLoading {
    width: 195px;
    height: 14px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 21.25px;
}
.subTitleLoading1 {
    width: 653px;
    height: 25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 16.25px;
    margin-top: 59px;
}
.subTitleLoading2 {
    width: 239px;
    height: 20px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 16.25px;
    margin: 58px 0px 0px 45px;
}
.subTitleLoading3 {
    width: 80px;
    height: 64px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 4px;
    margin-top: 59px;
    margin-left: 67px;
}
.subLoading1 {
    width: 167px;
    height: 12px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 40px;
    margin: 0px 0px 20px 16px;
}
.subLoading2 {
    width: 270px;
    height: 14px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    border-radius: 21.25px;
    margin: 0px 0px 20px 16px;
}
.subLoading3 {
    width: 300px;
    height: 122px;
    animation: shimmer 3s infinite;
    background: linear-gradient(
        270deg,
        rgba(219, 219, 219, 0.05) 0%,
        #dbdbdb 50%
    );
    margin: 0px 16px 0px 16px;
}
.wrapper {
    width: 0px;
    animation: fullView 0.5s forwards linear;
}

.canvasTitleLoading{
    width: 100vw;
    max-width: 653px;
    height: 25px;
    animation: shimmer 3s infinite;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    border-radius: 16.25px;
}

.canvasDescriptionLoading{
    width: 100vw;
    max-width: 239px;
    height: 20px;
    animation: shimmer 3s infinite;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    border-radius: 16.25px;
    margin-top: 64px;
}

.canvasItem1Loading{
    width: 100vw;
    max-width: 167px;
    height: 12px;
    animation: shimmer 3s infinite;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    border-radius: 40px;
    margin-top: 64px;
}

.canvasItem2Loading{
    width: 100vw;
    max-width: 270px;
    height: 12px;
    animation: shimmer 3s infinite;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    border-radius: 40px;
    margin-top: 20px;
}

.canvasItem3Loading{
    width: 100vw;
    max-width: 300px;
    height: 122px;
    animation: shimmer 3s infinite;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    margin-top: 20px;
}

@keyframes fullView {
    100% {
        width: 100%;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}
