.color-picker-wrapper {
    width: 64px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f9fa;
    border-radius: 4px;
    padding: 0px 10px;
    box-sizing: border-box;
    margin: 0px 16px;
    position: relative;
    cursor: pointer;
}

.color-preview {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
input[type='color'] {
    /* background-color: white;
    border: none;
    border-radius: 5px;
    height: 36px;
    width: 36px;
    transition: 0.3s;
    position: absolute;
    right: 30px; */
    position: absolute;
    right: 32px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    outline: none;
}

input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 24px;
    height: 24px;
}

input[type='color']::-webkit-color-swatch {
    border: 1px solid #bfc9d9;
    border-radius: 4px;
    padding: 0;
}

.color {
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.swatch {
    width: 75px;
    height: 36px;
    background: #f7f9fa;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.sketch-picker {
    position: absolute;
    z-index: 9999;
}
