.canvas-bg {
    background-repeat: no-repeat;
    /* width: 200vw;
    height: 200vh; */
    background-size: cover;
}

.put-side {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #d9ecff;
    border-radius: 8px;
    position: absolute;
    top: 12px;
}

.put-float {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #d9ecff;
    border-radius: 8px;
    position: absolute;
    top: 12px;
    right: 12px;
}
