.modal-overlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay-xl {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    overflow: overlay;
    position: fixed;
}

.modal-box {
    display: block;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    box-sizing: border-box;
    height: 90%;
    overflow: overlay;
}

.modal-box-xl {
    display: block;
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    box-sizing: border-box;
    margin: 20px 0px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
}
.title-text {
    width: 100%;
}
