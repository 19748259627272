.sub-loading-view {
    display: flex;
}
.student-loading {
    display: flex;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    align-items: center;
}
.subTitle-loading {
    padding: 19px;
    box-shadow: 0px 1px 0px #e3e4e5;
}
.student-list-loading {
    width: 268px;
    background: white;
}
.submission-list-loading {
    display: flex;
}
.submission-loading {
    margin: 70px 0px 0px 29px;
}
