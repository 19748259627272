.sub-header {
    padding: 14px 12px;
    box-shadow: 0px 1px 0px #e3e4e5;
}

.sidebar-item {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    padding: 4px 16px;
    cursor: pointer;
}

.sidebar-item:hover,
.active-item {
    background: #d9ecff;
}

.profile-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.profile-img img {
    width: 100%;
    height: 100%;
}

.profile-img span {
    position: absolute;
}

.pr-16 {
    padding-right: 16px;
}
