.canvas-loading-wrapper{
    position: relative;
    overflow: overlay;
}

.canvas-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    overflow: overlay;
    position: absolute;
}

.title-wrapper{
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
}

.body-wrapper{
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.group-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;

}