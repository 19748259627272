.text-style-tool-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #f7f9fa;
    border-radius: 4px;
    width: 120px;
    height: 36px;
    padding: 8px 0px;
    box-sizing: border-box;
    margin-left: 16px;
}

.text-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 154% */
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'case' on,
        'cv05' on, 'cv08' on;
    /* Carbon / C300 */
    color: #363f4d;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0px 8px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}
